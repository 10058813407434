#button {
    position: fixed;
    bottom: var(--padding-inline);
    right: var(--padding-inline);
    border: none;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--secondary-color);
    cursor: pointer;
    box-shadow: 4px 4px 40px var(--shadow-color-50);
    z-index: 9;
    opacity: 1;

    transition: opacity 0.2s ease;
}

#button.hide {
    opacity: 0;
    pointer-events: none;
}

.arrow {
    rotate: -90deg;
    stroke: white;
}
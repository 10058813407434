.wellness_article_link {
    text-decoration: none;
    color: inherit;
    height: 528px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.image {
    width: 360px;
    height: 360px;

    max-width: 80vw;
    max-height: 80vw;
    object-fit: cover;
    object-position: center top;
    border-radius: 16px;
}

.title {
    font-family: 'Jomolhari';
    font-size: 28px;
    line-height: 32px;
    margin-top: 23px;
    flex-grow: 1;
}
.heading_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 20px;
    margin-top: 50px;
}

#content_section_content {
    padding-inline: var(--padding-inline);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-bottom: 200px;
}

.iframe_container {
    width: 100%;
    height: 800px;
}
::part(scroll) {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative;
}

::part(wrapper) {
    position: relative;
    overflow: visible;
    z-index: 2;
}

::part(canvases) {
    position: absolute;
    top: 0;
}

::part(cursor) {
    pointer-events: none;
    position: absolute;
    z-index: 5;
    top: 0;
    /* left: 0; */
    height: 100%;
    border-radius: 2px;
}
.headshot {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    margin-block: 30px 20px;
}

.play_button {
    box-shadow: 0px 0px 40px var(--shadow-color-50);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.play_button>svg {
    width: 100%;
    height: 100%;
}

.subtitle {
    opacity: 0.7;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    flex-grow: 1;
}

.waveform_container {
    width: 100%;
    margin-block: 2px 10px;
}
#modal_background {
    position: fixed;
    inset: 0;
    background-color: var(--shadow-color-50);
    z-index: 10;

    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: var(--padding-inline); */
}

#modal_background.hide {
    display: none;
}

#member_modal {
    padding: 30px 40px;
    position: relative;
    width: 100%;
    max-height: calc(100vh - 2 * var(--padding-inline));
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-radius: 16px;

    box-shadow: 25px 25px 50px var(--shadow-color-25);
    background-color: #fff;

    text-align: center;
}

#close_button {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: transparent;
    border: none;

    width: 20px;
    height: 20px;
    cursor: pointer;
}

#close_button>img {
    width: 100%;
    height: 100%;
}

#headshot {
    width: 162px;
    height: 162px;
    border-radius: 50%;
    object-fit: cover;
}

#name {
    font-size: 18px;
    line-height: 18px;
}

.light_text {
    color: var(--light-blue);
    font-size: 14px;
}

#bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

#bio_overflow_wrapper {
    /* max-height: 100%; */
    overflow-y: scroll;
}

#bottom p {
    font-size: 14px;
}

#controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

#listen_button {
    border-color: transparent;
    background-color: var(--primary-color);
    color: white;
}

/* #connect_link {
    color: var(--primary-color);
    border-color: var(--primary-color);
} */
#content_section_content {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.all_orgs_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.link {
    font-size: 20px;
    color: var(--primary-color);
    text-decoration: none;
    padding: 10px;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    width: 100%;

}

.link_text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

}

.swatch {
    height: 30px;
    width: 8px;


}

.link:hover {
    text-decoration: underline solid 1px var(--primary-color);
}

.logo {
    height: 40px;
}
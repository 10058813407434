.body_graphic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 82px;
    /* min-height: 372px; */
    /* padding-block: 90px 100px; */
}

.body_graphic.reverse {
    flex-direction: row-reverse;
}

.graphic_container {
    position: relative;
}

.graphic_container>img {
    border-radius: 16px;
    width: 587px;
    height: 372px;
    /* height: 100%; */
    object-fit: cover;
}

.text {
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    height: 100%;
    min-height: 372px;

}

.dark_mode>.text {
    color: white;
}

.action_button {
    border: none;
    color: white !important;
}

@media (max-width: 1279px) {

    .body_graphic,
    .body_graphic.reverse {
        flex-direction: column;
        gap: 80px !important;
    }

    .graphic_container {
        align-self: center;
    }

    .text {
        min-height: 0;
        align-self: center;
    }

    .link,
    .action_button {
        margin-top: 20px;
        align-self: center;
    }

}
#footer {
    padding-inline: var(--padding-inline);
    padding-block: 140px 60px;
    max-width: 1440px;
    margin-inline: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 130px;
}

#footer.mobile {
    padding-block: 75px 30px;
}

#top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
}

#left {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#footer strong,
.light_heading {
    line-height: 24px;
}

.light_heading {
    font-size: 14px;
}

/* #right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
} */

.links_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.link {
    line-height: 24px;
    color: inherit;
    text-decoration: none;
}


#logos_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 500px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#logos_container.smaller_logos {
    gap: 16px;
}

#logos_container.smaller_logos>img {
    height: 30px;

}

#logos_container>img {
    height: 50px;
    object-fit: contain;
}

#bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 26px;
}

#line {
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--primary-color);
}

#legal_container {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

#copyright_mobile {
    font-size: 12px;
}
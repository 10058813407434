.email_option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    padding: 14px;
    border-radius: 16px;
    border: 1px solid #dedede;
    /* box-shadow: 2px 2px 20px var(--shadow-color-25); */

    text-decoration: none;
    color: var(--primary-color);
}

.icon {
    width: 30px;
    height: 30px;
}

.description {
    color: #898989;
    font-size: 14px;
}

.name {
    font-size: 18px;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
}
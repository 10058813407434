.tool_card {
    background-color: white;
    border: white 2px solid;
    border-radius: 16px;
    box-shadow: 25px 25px 50px var(--shadow-color-25);
    padding: 30px;
    padding-bottom: 40px;

    display: grid;
    grid-template-rows: 143px 1fr;
    max-width: 360px;
    /* height: 380px; */
    height: 100%;
    /* min-height: max-content; */

}

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.heading {
    font-size: 24px;
    line-height: 32px;
}

.text {
    line-height: 24px;
    flex-grow: 1;
}

.link {
    margin-top: 5px;
    text-decoration: none;
    color: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
#index_section {
    --starter-tiles-size: max(140px, 30vw);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 35px;
    padding-bottom: 100px;

}

#index_container {
    width: 100%;
    min-width: fit-content;
    padding-top: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;

}

#index_background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #002C61 59.28%, #000000 149.62%);
    border-bottom-right-radius: 30px;
}

#index_container>h1 {
    color: white;

}

#header_container {
    /* padding-inline: var(--padding-inline); */
    width: 100%;
}

#sign_in_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 14px 20px;
    min-width: 170px;
    margin-top: 80px;
}

#starter_tiles_grid {
    display: grid;
    grid-template-columns: repeat(2, var(--starter-tiles-size));
    gap: 15px;
    /* width: 100%; */
    width: calc(100vw - 2 * var(--padding-inline));
    justify-content: center;
}

#heading {
    /* max-width: calc(100vw - 2 * var(--padding-inline));
    width: 100%; */
    max-width: min(calc(15px + 2 * var(--starter-tiles-size)), 100vw);
    /* padding-inline: var(--padding-inline); */
    align-self: center;
}
#wellness_section_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
}

#wellness_hub_heading {
    max-width: 900px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#wellness_hub_articles_container {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: flex-start;
    gap: 40px;
    padding-inline: var(--padding-inline);
    overflow: scroll;
}

#wellness_hub_articles_overflow_container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

#scroll_cover_left {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 150px;
    height: 100%;
    background-image: linear-gradient(to right, #fff 50%, #ffffff00 70%);
}

#scroll_cover_right {
    position: absolute;
    content: "";
    right: 0px;
    top: 0;
    width: 150px;
    height: 100%;
    background-image: linear-gradient(to left, #fff 50%, #ffffff00 70%);
}


.hover_arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    top: 180px;
}

#left_hover_arrow {
    left: 40px;
}

#right_hover_arrow {
    right: 40px;
}
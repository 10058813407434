/* Jomolhari */

@font-face {
  font-family: 'Jomolhari';
  src: url('./fonts/Jomolhari/Jomolhari-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Inter */

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter_28pt-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
#header_container {
    width: 100%;
    background-color: var(--primary-color);
}

#hero {
    background-color: var(--primary-color);
    border-radius: 0 0 80px 80px;
    color: white;


    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 375px);
}

#hero_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 10vh; */
    align-items: center;
    flex-grow: 1;
    padding-inline: var(--padding-inline);

}

#hero_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    margin-block: 40px 80px;
}

#hero_text>h1 {
    max-width: 806px;

}

#starter_tiles_container {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; */
    --num-tiles-max-4: 4;
    display: grid;
    grid-template-columns: repeat(var(--num-tiles-max-4), 1fr);
    gap: 20px;
}

#starter_tiles_container.six_tiles {
    grid-template-columns: repeat(3, 1fr);
}

.starter_tiles_group {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#scroll_arrows_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
}

#scroll_arrows_button {
    background-color: transparent;
    border: none;
    height: 45px;
    width: 30px;
    cursor: pointer;
}

#scroll_arrows_button>img {
    width: 100%;
    height: 100%;
}


#join_program_section {
    padding-top: 100px;
}

.section {
    padding: 90px var(--padding-inline);

}

.dark_section {
    background-color: var(--primary-color);
}

.body_graphic_content {
    flex-grow: 1;
}

.center_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;

    gap: 16px;
    text-align: center;
}

.center_heading>p {
    max-width: 465px;
}

#tool_cards_container {
    --num-tiles-max-3: 3;
    display: grid;
    grid-template-columns: repeat(var(--num-tiles-max-3), 1fr);
    justify-content: center;
    gap: 20px;
    justify-items: center;
    padding-bottom: 100px;
    color: var(--primary-color);
    align-self: center;

    /* constrain max width when pillars removed */
    width: 100%;
    max-width: calc(467px * var(--num-tiles-max-3) + 20px * (var(--num-tiles-max-3) - 1));
}

#tools_section {
    padding-bottom: 0;
}

#tools_section:nth-child(6) {
    /* keel reels section is gone */
    color: white;
    background-color: var(--primary-color);
}

#tools_section_content {
    display: flex;
    flex-direction: column;
    gap: 70px;
}

#reels_intro_container {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    height: 850px;
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

#create_reel_link {
    margin-top: 10px;
}

.grey_section {
    background-color: var(--light-grey);
}

#anonymous_tools_body_graphic {
    gap: 200px;
}

/* #self_assessment_section {
    margin-top: 50px;
} */

#stats_card {
    /* width: 100%; */
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 68px;
    padding: 50px;
    border-radius: 16px;
    /* margin-inline: var(--padding-inline); */
}

#stats_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    gap: 50px;
}

.stat {
    max-width: 200px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.stat>strong {
    font-size: 60px;
    font-weight: 600;
    line-height: 68px;
    color: var(--secondary-color)
}



#play_button_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 20px 6px 16px 6px;

    max-width: 106px;
}


#play_button_overlay>img {
    width: 100%;
}

#your_peer_supporter_overlay {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 10px 12px;

    color: white;
    font-size: 12px;

}

#your_peer_supporter_text {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 16px;

}

#your_peer_supporter_text>p {
    opacity: 0.5;
}

#stress_level_overlay strong {
    font-weight: 600;
}

@media (max-width: 1279px) {
    #starter_tiles_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    #starter_tiles_container.six_tiles {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
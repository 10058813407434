.overlay {
    position: absolute;
}

.default {
    border: 1px solid white;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.3);

    -webkit-backdrop-filter: blur(6.5px);
    backdrop-filter: blur(6.5px);
}
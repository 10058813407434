.hamburger_button {
    width: 60px;
    height: 60px;
    background-color: #00000000;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 16px;
    cursor: pointer;
}

.hamburger_button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.hamburger_button .line {
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: white;
}

.hamburger_menu_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    /* overflow: scroll; */
    /* min-height: fit-content; */

}

.hamburger_menu_wrapper .cover {
    background: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity ease 0.5s;
}

.menu_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    gap: 10px;
}

#logo {
    width: 150px;
    object-fit: contain;
    object-position: top;
}

.menu_scroll_wrapper {
    position: relative;
    width: 380px;
    padding-top: 50px;
    padding-left: 40px;
    height: 100%;
    max-height: 100vh;
    overflow: scroll;
    background-color: #0C254E;

    transition: width ease 0.5s, padding-left ease 0.5s;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.menu_scroll_wrapper::_webkit_scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu_scroll_wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    min-height: fit-content;
    padding-bottom: 100px;
    /* width: 380px; */

}

/* #home_page .menu {
    padding-top: 89.5px;
} */

.menu a:is(*) {
    /* margin-left: 40px; */
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    width: max-content;
    opacity: 1;
}

.menu a,
.close_button {
    transition: opacity ease 0.25s;
}

.hamburger_menu_wrapper:not(.show) {
    pointer-events: none;
}

.hamburger_menu_wrapper:not(.show) .cover {
    opacity: 0;
}

.hamburger_menu_wrapper:not(.show) .menu_scroll_wrapper {
    width: 0;
    padding-left: 0;
}

.hamburger_menu_wrapper:not(.show) *:is(.menu a, .close_button) {
    opacity: 0;
}

.close_button {
    position: relative;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: transparent;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    opacity: 1;
    padding: 15px;
    /* margin-top: 20px; */
    /* align-self: flex-end; */
}

.close_button>img {
    width: 100%;
}

.close_button:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.hamburger_button.dark {
    background-color: #00000000;
    border: 1px solid var(--primary-color);
}

.hamburger_button.dark:hover {
    background-color: rgba(0, 0, 0, 0.1);
}


.hamburger_button.dark .line {
    background-color: var(--primary-color);
}


@media (max-width: 660px) {
    .menu_scroll_wrapper {
        width: 100%;
        padding-left: 0;
    }

    .menu {
        padding-inline: 40px;
        /* align-items: center; */
        gap: 6vw;
    }

    .menu .link {
        font-size: clamp(14px, 7.3vw, 36px);
        text-align: center;
        /* 600px */
    }

    .menu_header {
        margin-bottom: 5vw;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        /* padding-right: 40px; */
    }

    /* .close_button {
        display: flex;

        width: 50px;
        height: 50px;
        min-width: 50px;
        align-self: center;
    } */

    .hamburger_button {
        width: 50px;
        height: 50px;
        min-width: 50px;
        padding: 12px;
    }

    #logo {
        width: 130px;
        height: 130px;

        max-width: 35vw;
        max-height: 35vw;
    }
}
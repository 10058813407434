.heading_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 20px;
    margin-top: 50px;
}

#content_section_content {
    padding-inline: var(--padding-inline);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-bottom: 200px;
}

.options_container {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px; */

    margin-top: 60px;

    display: grid;
    grid-template-columns: repeat(auto-fit, min(100%, 270px));
    width: 100%;
    justify-content: center;
    gap: 20px;
}
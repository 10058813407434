#members_list {
    width: 100%;
    height: 429px;
    background-color: #fff;
    box-shadow: 15px 15px 50px var(--shadow-color-25);
    border-radius: 16px;
    padding: 18px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
}

#top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

#bottom {
    width: 100%;
}

.light_text {
    font-size: 14px;
    line-height: 16px;
    color: var(--light-blue);
}

.member {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    border: 0px;
    background-color: transparent;
    padding-block: 12px;
    border-bottom: 1px solid var(--shadow-color-50);
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
}

.member_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;
}

.member_headshot {
    width: 63px;
    height: 63px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.member_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
}

.member_text>strong {
    line-height: 18px;
}
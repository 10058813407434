#reels_list {
    border-top: 1px solid var(--shadow-color-50);
    width: 100%;
    margin-bottom: 100px;
}

.reel_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 70px;

    border-bottom: 1px solid var(--shadow-color-50);
}

.left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-grow: 1;
}

.connect_button {
    cursor: pointer;
}
.starter_tile {
    text-decoration: none;
    color: white;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    padding: 30px;
    border: 1px solid transparent;
    background-color: transparent;
    box-shadow: 25px 25px 50px transparent;
    transition: all 200ms ease;
    cursor: pointer;
}

.starter_tile.white_tile {
    color: var(--primary-color);
}

.starter_tile.mobile {
    padding: 15px;
    width: 100%;
    /* height: 100px; */
    aspect-ratio: 1;
    transition: none;
    box-shadow: 25px 25px 50px var(--shadow-color-25);

}

.starter_tile.desktop {
    width: 270px;
    height: 270px;
}


.starter_tile.desktop:hover {
    border-color: var(--secondary-color);
    box-shadow: 25px 25px 50px var(--shadow-color-50);
}

.starter_tile.desktop:hover .cover {
    background-color: var(--secondary-color);
    opacity: 55%;
}

.starter_tile svg {
    transition: stroke 200ms ease;
}

.starter_tile.desktop:hover svg {
    stroke: white;
}


.image,
.cover {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    /* width: 270px;
    height: 270px; */
    inset: 0;
    position: absolute;
    object-fit: cover;
}

.cover {
    z-index: 2;
    background-color: var(--primary-color);
    opacity: 65%;
    transition: background-color 200ms ease;
}

.image {
    z-index: 1;
    filter: grayscale(1);
}

.starter_tile.white_tile .image {
    display: none;
}

.starter_tile.white_tile .cover {
    opacity: 1;
    background-color: white;
}

.text {
    z-index: 3;

    display: flex;
    flex-direction: column;
    gap: 8px;

    white-space: pre-wrap;
}

.starter_tile.mobile .text {
    gap: 4px;
    font-size: 16px;
    /* line-height: 24px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    gap: 10px;
}

.starter_tile.desktop .label {
    font-size: 24px;
    line-height: 32px;
}

.graphic_container {
    z-index: 2;
}

.graphic {
    height: 56px;
    width: fit-content;
}

.mobile_arrow {
    float: right;
    min-width: 16px;
    max-width: 16px;
    margin-bottom: 4px;
}
#index_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 35px;


}

#index_container {
    padding-top: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 40px;
    /* padding-inline: var(--padding-inline); */
    width: 100%;
    min-width: max-content;


}

#index_background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #012C61 60.86%, #181D27 139.77%);

}

#index_container>h1 {
    color: white;

}

#header_container {
    width: 100%;
}

#back_home_link {
    margin-top: 340px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 14px 20px;
    min-width: 170px;
    /* margin-left: var(--padding-inline); */
}

#starter_tiles_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 100%;
}
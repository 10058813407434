#header {
    --big-logo-w: 200px;
    --big-logo-h: 150px;
    --small-logo-w: 150px;
    --small-logo-h: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    padding-inline: var(--padding-inline);
}

#header.desktop {
    padding-top: 25px;
}

#left {
    min-width: var(--big-logo-w);
    width: var(--big-logo-w);
    height: var(--big-logo-h);
    margin-top: 25px;
}

#logo {
    width: 100%;
    height: 100%;
    object-position: top left;
    object-fit: contain;
}

#header.mobile>#left {
    width: var(--small-logo-w);
}


.link {
    position: relative;
    display: block;
    color: white;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
}

#header.dark_mode .link {
    color: var(--primary-color);
}

.link.active::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    bottom: -2px;
    background-color: #fff;
}

#header.dark_mode .link.active::after {

    background-color: var(--primary-color);
}


#right>a {
    font-size: 12px;
    line-height: 20px;
}

#right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

#middle {
    display: none;
    /* display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 50px;
    padding-top: 12px; */
}

#right,
#middle {
    margin-top: 25px;
}

#join_button {
    border: none;
    color: white;

}

/* @media (min-width: 1280px) {
    .hamburger {
        display: none;
    }
} */

/* @media (max-width: 1279px) {
    #middle {
        display: none;
    }
} */

@media (max-width: 660px) {
    #left {
        min-width: var(--small-logo-w);
        min-height: var(--small-logo-h);
        width: var(--small-logo-w);
        height: var(--small-logo-h);
        margin-top: 0;
    }

    #right {
        margin-top: 0;
    }

    #right #login_link {
        font-size: 10px;
        min-width: max-content;
    }
}
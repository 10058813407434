.disabled_intro_reel_pill,
.intro_reel_pill {
    position: absolute;
    top: 50%;
    left: 50%;
}

.intro_reel_pill {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 44px 12px 12px;
    border: 1px solid white;
    border-radius: 99px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    line-height: 18px;
}

.headshot {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.org_name {
    /* font-weight: 300; */
    opacity: 0.75;
}

.outer_circle {
    position: absolute;
    top: 50%;
    right: 4px;

    width: 45px;
    height: 45px;

    translate: 50% -50%;
    border-radius: 50%;

    padding: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.50);
    -webkit-backdrop-filter: blur(6.5px);
    backdrop-filter: blur(6.5px);
}
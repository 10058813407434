#content_section_content {
    padding-inline: var(--padding-inline);

    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 50px;
}

#hero {
    width: 100%;
    position: relative;
    border-radius: 20px;
}

#hero_image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    z-index: 1;
    /* filter: blur(5px); */
}

#hero_image_cover {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    z-index: 2;
    background-color: var(--primary-color);
    opacity: 50%;
}

#title {
    position: relative;
    padding: 160px var(--padding-inline);
    z-index: 3;
    color: white;
    text-align: center;
    font-family: 'Jomolhari';
    font-weight: 400;
}

#wellness_carousel_section {
    margin-top: 250px
}

#content_container {
    padding-inline: 20px;
    padding-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
}

#content_container>*:is(ul, ol) {
    padding-left: 20px;
}

@media(max-width: 660px) {
    #title {
        padding: var(--padding-inline);
        font-size: 30px;
    }
}
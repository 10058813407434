:root {
    --primary-color: #002C61;
    --light-blue: #6F8092;
    --shadow-color-50: #002C6133;
    --shadow-color-25: #002C611A;

    /* sherrif color */
    --secondary-color: #FF5E1A;
    --light-grey: #ECEDEF;

    font-family: 'Inter', sans-serif;

    color: var(--primary-color);

    --padding-inline: 70px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



h1.smaller {
    font-size: 52px;
    line-height: 60px;
}

h1>span {
    color: var(--secondary-color)
}

.button {
    padding: 12px 24px;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 24px;
}

.section-content {
    max-width: 1440px;
    margin-inline: auto;
    width: 100%;
}


button:not(.default) {
    /* border: none; */
    /* background-color: transparent; */
    text-align: inherit;
    color: inherit;
}

.link-button {
    background-color: var(--secondary-color);
    display: flex;
    width: fit-content;
    padding: 12px 24px;
    border-radius: 28px;
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.link-button.outline {
    background-color: transparent;
    border: 1px solid white;
    padding: 11px 24px;
}

.link-button.outline.dark-mode {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

h2 {
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
}

.mobile-cap {
    width: calc(355px + 2 * var(--padding-inline));
    margin-inline: auto;
    padding-inline: var(--padding-inline);
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

@media (max-width: 660px) {
    :root {
        --padding-inline: 24px;
    }

    .desktop {
        display: none !important;
    }

    h1 {
        font-size: 40px;
        line-height: 45px;
        font-weight: 600;
    }
}

@media (min-width: 661px) {

    .mobile {
        display: none !important;
    }

    h1 {
        font-size: 60px;
        line-height: 68px;
        font-weight: 600;
    }
}
#reels_section_content {
    padding-inline: var(--padding-inline);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 22px;
    padding-bottom: 50px;
}

#connections_heading {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
}

#connections_heading_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    align-items: center;
}

#connections_heading_row>p {
    font-size: 20px;
    line-height: 28px;
}

#connections_heading_row>h1 {
    font-size: 52px;
    line-height: 60px;
}

#browse_link {
    margin-block: 48px 70px;
    border: none;
    color: white;
}